@import "../../../../public/assets/scss/base/variables";
.locations {
  background-color: rgb(252, 252, 252);

  img {
    transition: 1s;
    box-shadow: 10px 10px 20px 0px #888888;

    &:hover {
      opacity: 0.9;
      transition: 0.5s;
      box-shadow: 10px 10px 20px 0px #888888;
    }
  }

  & .locationWrapper {
    img {
      filter: brightness(0.95);
      // border: 5px solid rgba(255, 217, 0, 0.356);
      // border-style: outset;
      transition: 0.5s;

      // &:hover {
      //   transform: scale(1.1) translate3d(0, 0, 0);
      //   -webkit-transform-style: preserve-3d;
      //   -webkit-backface-visibility: hidden;
      //   transition: 1s;
      // }
    }
  }
}
.about-page-wrap {
  p {
    margin-bottom: 30px;
  }
}
.text-black {
  color: $black4;
  font-weight: 500;
  letter-spacing: 0.35px;
  margin-bottom: 30px;
}
.content-right {
  padding: 50px 0px 0px 100px;
  @media (max-width: 992px) {
    padding: 30px 15px 0 15px;
  }
}
.content-left {
  padding: 50px 100px 0px 0px;
  @media (max-width: 992px) {
    padding: 0 15px;
  }
}
.about-parallax {
  padding: 110px 0px;
  p {
    font-size: 18px;
    color: $black1;
    margin-bottom: 30px;
    line-height: 1.75;
  }
}

.box-counter {
  @media (max-width: 575px) {
    margin-bottom: 30px;
  }
  .counter-nummer {
    font-size: 36px;
    font-family: $headings-font-family;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  .counter-text {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.33px;
  }
}

.box-ourteam {
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-color: #ebebeb;
  padding: 40px 40px 40px 40px;
  @media (max-width: 992px) {
    padding: 15px;
  }
  &.no-border {
    border-width: 0;
  }
  @media (max-width: 992px) {
    border-width: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
  }
  .avarta-team {
    margin-bottom: 40px;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
  .name {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .job {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 35px;
    display: block;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
  &.flex-column-reverse {
    .content-team {
      margin-bottom: 20px;
    }
  }
}
.ourteam {
  .no-gutters {
    margin-left: -40px;
    margin-right: -40px;
    @media (max-width: 992px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  [class*="col-"]:last-child {
    .box-ourteam {
      border-width: 0;
    }
  }
}
