@import "../../../../public/assets/scss/base/variables";
ul.apartment-items {
  padding: 0;
  margin-bottom: 50px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 0;
    border-bottom: 1px solid $border-color;
    font-size: 13px;
    // cursor: pointer;
    .value-list {
      font-weight: 500;
      color: $headings-color;
    }
  }
}

.react-tabs {
  p {
    color: $headings-color;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.4px;
  }
  .react-tabs__tab-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    li {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 18px;
      letter-spacing: 2.4px;
      color: #3f4448;
      background-color: #f8f8f8;
      text-align: center;
      font-family: $font-family-base;
      margin: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 20px 25px;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      -ms-flex: 1 !important;
      flex: 1 !important;
      &:hover,
      &.react-tabs__tab--selected {
        background-color: $primary;
        color: $white;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          bottom: -6px;
          left: 50%;
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          border-top: 6px solid;
          border-left: 8px solid transparent !important;
          border-right: 8px solid transparent !important;
          border-bottom: 0;
          border-top-color: $primary;
          @media (max-width: 767px) {
            content: none;
          }
        }
      }
      &:after {
        background-color: transparent;
        content: none;
      }
    }
  }
  .row {
    align-items: center;
  }
  .apartment-img {
    padding-left: 80px;
    @media (max-width: 992px) {
      padding-left: 0 !important;
      padding-top: 30px;
    }
  }
  .flex-row-reverse {
    .apartment-img {
      padding-right: 80px;
      padding-left: 0;
      @media (max-width: 992px) {
        padding-right: 0px !important;
      }
    }
  }
}

.roomsSlider {
  overflow: hidden;

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        // opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
      //   opacity: $slick-opacity-not-active;
    }
    &:before {
      //   font-family: $slick-font-family;
      font-size: 60px;
      line-height: 1;
      color: rgb(53, 52, 52);
      opacity: 0.8;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .imgWrapper {
    height: 33vw;

    @media (max-width: 992px) {
      height: 90vw;
    }
  }

  .slider-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition: all 0.4s;
  }

  .slick-prev {
    left: 10px;
    [dir="rtl"] & {
      left: auto;
      right: 10px;
    }
  }

  .slick-next {
    right: 10px;
    [dir="rtl"] & {
      left: 10px;
      right: auto;
    }
  }

  @media (max-width: 768px) {
    .slick-prev {
      left: -3px;
      [dir="rtl"] & {
        left: auto;
        right: -3px;
      }
    }

    .slick-next {
      right: -3px;
      [dir="rtl"] & {
        left: -3px;
        right: auto;
      }
    }
  }

  .slick-arrow {
    z-index: 2;
    &:before {
      font-size: 50px;
      font-family: FontAwesome;
    }
    @media (max-width: 768px) {
      display: block !important;
    }
  }
}
