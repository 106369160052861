@import "../../../../public/assets/scss/base/variables";
.block-table {
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  .table {
    th {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.2em;
      padding: 2em 1em;
      border-bottom: 1px solid #959595;
      white-space: nowrap;
      border-top: 0;
      color: $headings-color;
    }
    td {
      padding: 1em 1em;
      border-bottom: 1px solid #ebebeb;
      color: $headings-color;
      span {
        font-size: 13px;
        font-weight: 400;
        color: $body-color;
      }
      a {
        background-color: #f8f8f8;
        border: 0;
        white-space: nowrap;
        font-size: 12px;
        color: $headings-color;
        font-weight: bold;
        letter-spacing: 0.2em;
        padding: 0.75rem 2rem;
        &:hover {
          transition: all 0.3ms ease-in-out;
          background-color: $primary;
          color: $white;
        }
      }
    }
    tr:first-child {
      td {
        padding-top: 2rem;
      }
    }
  }
}
.apartment-block-wrap {
  @media (max-width: 767px) {
    margin-left: 15px;
    margin-right: 15px;
  }
  .row {
    .property {
      margin-bottom: 30px;
    }
    &.no-gutters {
      .property {
        margin-bottom: 0;
      }
    }
  }
  .pagination {
    justify-content: center;
  }
}
.property {
  .property-wrap {
    position: relative;
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
    .post-thumbnail {
      overflow: hidden;
      box-shadow: 10px 5px 15px lightgray;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background: -webkit-linear-gradient(
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.65) 100%
        );
      }
      .img-apartment {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all ease-in-out 0.5s;
      }
    }
    .entry-content {
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: 0;
      margin-bottom: 0;
      padding-top: 85px;
      padding-bottom: 55px;
      max-height: 100%;
      z-index: 99;
      background-image: -webkit-linear-gradient(
        90deg,
        #3f4448 0%,
        rgba(37, 39, 40, 0) 100%
      );
      max-width: 100%;
      .property-title {
        > div {
          // font-family: $font-family-heading;
          color: rgba(255, 255, 255, 0.85);

          span {
            font-size: 20px;
            font-weight: 900;
          }

          div {
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
      .link-more {
        font-size: 10px;
        letter-spacing: 2px;
      }
    }
    &:hover {
      .post-thumbnail {
        .img-apartment {
          transform: scale(1.1);
          transition: all ease-in-out 0.5s;
        }
      }
    }
  }
}
