@import "../../../../public/assets/scss/base/variables";

.gallery {
  .imgWrapper {
    // max-height: 14vw;
    // min-height: 14vw;

    & img {
      max-height: 100%;
      min-height: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
}
