@import "../../../../public/assets/scss/base/variables";

.apartment {
  $this: &;
  $mainBg: #444343;
  // $mainBg: rgb(75, 67, 67);
  // $mainBg: #d8c8a0;
  $secBg: rgb(75, 67, 67);
  // $secBg: #d8c8a0;
  // navBtn #cabe9f
  #{$this}__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    #{$this}__imageWrapper {
      & img {
        max-height: 100%;
        max-width: 100%;
        min-height: 100%;
        min-width: 100%;
      }
    }

    & table {
      outline: 5px solid $mainBg;
      border: none;
      color: rgba(0, 0, 0, 0.788);
      font-size: 2.5vw;

      @media (min-width: 768px) {
        font-size: 0.5rem;
      }

      @media (min-width: 1025px) {
        font-size: 0.8rem;
      }

      & th {
        border: none;
        font-weight: 900;
        background-color: $mainBg;
        color: white;

        @media (min-width: 768px) {
          font-size: 0.6rem;
        }

        @media (min-width: 1025px) {
          font-size: 0.9rem;
        }
      }

      & td {
        font-weight: 500;
        border: 1.5px solid $mainBg;
        border: 1px solid $mainBg;
        background-color: #fff;
        padding: 2vw 0.5vw;

        @media (min-width: 768px) {
          padding: 0.7vw 0.7vw;
        }

        @media (min-width: 1025px) {
          padding: 0.4vw 0vw;
        }

        &.different {
          background-color: #ead79c;
          background-color: $secBg;
          color: white;
        }
      }
    }
  }
}
