@import "../../../../public/assets/scss/base/variables";
.slider-image {
  background-size: cover;
  background-position: top center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.4s;
}
.slider-content {
  z-index: 1;
  overflow: hidden;
  position: relative;
  .btn-link {
    color: $primary !important;
    cursor: pointer;
    &:hover {
      color: darken($primary, 10%) !important;
    }
  }

  .catalogWrapper {
    margin-top: 2rem;
    transition: 0.3s;

    &:hover {
      filter: brightness(1.2);
      transition: 0.3s;
    }

    a {
      background-color: rgba(15, 10, 10, 0.336);
      border: 3px solid #cb952a63;
      padding: 1rem;
      font-weight: 900;
      color: #cb942a;
      letter-spacing: 1px;
    }
  }
}
.slider-wrap {
  .slider-content {
    height: 100vh;
    text-transform: uppercase;
    font-size: 13px;
    font-family: $font-family-heading;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    h2 {
      @media screen and (min-width: 992px) {
        font-size: 72px;
        line-height: 82px;
      }
      @media (max-width: 992px) and (min-width: 767px) {
        font-size: 60px;
        line-height: 70px;
      }
      @media (max-width: 767px) and (min-width: 576px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 576px) {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -1px;
      }
    }
    h5 {
      font-family: $font-family-base;
      font-weight: 300;
      line-height: 30px;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 26px;
      }
    }
  }
  .slider-footer {
    position: absolute;
    bottom: 50px;
    left: 0;
    @media (max-width: 767px) {
      text-align: center !important;
      font-size: 12px;
    }
  }
}

.carousel1-wrap {
  font-size: 14px;
  line-height: 26px;
  .slider-info {
    background-color: $dark;
    padding: 30px 15px;
    @media screen and (min-width: 576px) {
      padding: 35px;
      min-width: 396px;
      max-width: 396px;
    }
  }
  .slider-content {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .navigation {
  }
  .arrow-button {
    height: 70px;
    width: 70px;
    font-size: 0px;
    cursor: pointer;
    color: transparent;
    padding: 0;
    border: none;
    outline: none;
    background-color: $primary;
    &:hover {
      background-color: darken($primary, 10%);
    }
    &:before {
      font-size: 36px;
      line-height: 70px;
      font-family: FontAwesome;
      color: $white;
    }
    &.arrow-prev {
      &:before {
        content: "\f104";
      }
    }
    &.arrow-next {
      &:before {
        content: "\f105";
      }
    }
  }
}
.carousel2-wrap {
  background-color: $dark;
  .slider-content {
    @media screen and (min-width: 1200px) {
      padding-right: 28%;
    }
    @media (max-width: 1200px) and (min-width: 992px) {
      padding-bottom: 30px;
      padding-top: 30px;
      .slider-desc {
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        max-height: 120px;
      }
    }
    @media (max-width: 992px) and (min-width: 767px) {
      padding-bottom: 50px;
      padding-top: 30px;
      margin-right: 30px;
    }
    @media (max-width: 767px) {
      padding: 30px;
    }
    @media (max-width: 576px) {
      padding: 30px 15px;
    }
  }
  .navigation {
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
}

.slick-arrow {
  z-index: 2;
  &:before {
    font-size: 50px;
    font-family: FontAwesome;
  }
  @media (max-width: 768px) {
    display: none !important;
  }
}
.slick-next {
  right: 25px;
  &:before {
    content: "\f105";
  }
}
.slick-prev {
  left: 25px;
  &:before {
    content: "\f104";
  }
}
.slick-dots {
  bottom: 25px;
  li {
    margin: 0;
    button {
      &:before {
        color: #ffffff;
        font-size: 15px;
        opacity: 0.5;
      }
    }
    &.slick-active {
      button {
        &:before {
          color: #ffffff;
        }
      }
    }
  }
}

.bullets-vertical {
  list-style: none;
  padding: 0;
  margin-left: 55px;
  display: block;
  height: 78px;
  @media (max-width: 767px) {
    height: 12px;
    margin-left: 20px;
  }
  @media (max-width: 576px) {
    margin-left: 5px;
  }

  .bullet-item {
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    background: transparent;
    width: 12px;
    height: 12px;
    position: relative;
    margin: 10px 0;
    @media (max-width: 767px) {
      display: inline-block;
      margin: 10px;
    }
    &.selected,
    &:hover {
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
      border: none;
      border-radius: 50%;
      background: transparent;
      .bullet-inner {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        background-color: rgb(255, 255, 255);
      }
    }
  }
  .bullet-inner {
    -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
    transition: background-color 0.3s ease, transform 0.3s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.3);
    text-indent: -999em;
    cursor: pointer;
    position: absolute;
  }
}
